import { getSession } from 'next-auth/react'
import ErrorPage from 'next/error'
import Bugsnag from '../lib/bugsnag'

const ErrorView = (props) => {
  return <ErrorPage statusCode={props.statusCode || '¯\\_(ツ)_/¯'} />
}

ErrorView.getInitialProps = async (ctx) => {
  const session = await getSession(ctx)

  if (ctx.err)
    Bugsnag.notify(ctx.err, function (event) {
      // event.setUser(sessionId, session.user.email, "");
      event.addMetadata('User', {
        id: session!.user.id,
      })
      // event.addMetadata("Undercover", { undercover: session.is_undercover });
    })
  return ErrorPage.getInitialProps(ctx)
}

export default ErrorView
