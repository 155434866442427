import {
  Box,
  Button,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/styles';
import { signOut, useSession } from 'next-auth/react';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import 'reflect-metadata';
import { Repository } from './icons/IconRepository';

const PREFIX = 'Sidebar';

const classes = {
  root: `${PREFIX}-root`,
  logo: `${PREFIX}-logo`,
  menuButton: `${PREFIX}-menuButton`,
  hide: `${PREFIX}-hide`,
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  content: `${PREFIX}-content`,
  large: `${PREFIX}-large`,
  icon: `${PREFIX}-icon`,
  divider: `${PREFIX}-divider`,
  toolbar: `${PREFIX}-toolbar`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
  },

  [`& .${classes.logo}`]: {
    width: 40,
    height: 40,
    marginRight: 10,
  },

  [`& .${classes.menuButton}`]: {
    marginRight: theme.spacing(2),
  },

  [`& .${classes.hide}`]: {
    display: 'none',
  },

  [`& .${classes.drawer}`]: {
    width: "280px",
    flexShrink: 0,
  },

  [`& .${classes.drawerPaper}`]: {
    width: "280px",
    backgroundColor: '#fff',
    color: '#666',
    justifyContent: 'space-between',
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },

  [`& .${classes.large}`]: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },

  [`& .${classes.icon}`]: {
    color: '#ccc',
  },

  [`& .${classes.divider}`]: {
    backgroundColor: '#ffffff1f',
  },

  [`& .${classes.toolbar}`]: {
    ...theme.mixins.toolbar,
    color: 'white',
    backgroundColor: '#0c2c63',
  }
}));

export default function Sidebar({
  mobileOpen,
  handleDrawerToggle,
}) {
  const { t } = useTranslation('common')
  const { data: session } = useSession()

  const router = useRouter()
  const theme = useTheme()

  const name: string | undefined = session?.user
    ? session.user.name ||
    session.user.email
    : undefined

  const listItems = [
    {
      title: 'dashboard',
      route: '/',
      visible: true,
    },
    {
      title: 'opportunities',
      route: '/opportunities',
      beta: 'opportunities',
      visible: true,
    },
    // {
    //   title: 'learning_courses',
    //   route: '/courses',
    //   visible: true,
    // },
    {
      title: 'sales_effectiveness_diagnostic',
      route: '/sales_effectiveness_diagnostic',
      visible: session?.user?.role === 'admin',
    },
    {
      title: 'customer_service_diagnostic',
      route: '/customer_service_diagnostic',
      visible: session?.user?.role === 'admin',
    },
    {
      title: 'settings',
      route: '/settings',
      visible: session?.user?.role === 'admin',
    },
  ]

  const drawer = (
    <Box
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      paddingBottom="40px"
    >
      <Box>
        <div className={classes.toolbar}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="64px"
          >
            <Image
              src="/static/logo.png"
              alt="logo"
              className={classes.logo}
              width={40}
              height={40}
            ></Image>
            <Typography variant="h6" noWrap>
              Paradym Shift
            </Typography>
          </Box>
        </div>
        <List style={{ padding: '0px' }}>
          {listItems
            .filter((item) => item.visible)
            .map((item) => (
              <Link passHref key={item.title} href={item.route}>
                <ListItem
                  selected={
                    router.pathname === item.route ||
                    (item.route !== '/' && router.pathname.includes(item.route))
                  }
                  button
                  key={item.title}
                >
                  <ListItemIcon className={classes.icon}>
                    {Repository[item.title]}
                  </ListItemIcon>
                  <ListItemText primary={t(`layout.sidebar.${item.title}`)} />
                </ListItem>
              </Link>
            ))}
        </List>
      </Box>
      {session?.user && (
        <Box textAlign="center">
          <Box marginBottom="20px">
            <Typography>Signed in as</Typography>
            <Typography>{name}</Typography>
          </Box>
          <Button variant="outlined" onClick={() => signOut()}>
            Sign Out
          </Button>
        </Box>
      )}
    </Box>
  )

  return (
    (<Root>
      <Hidden smUp implementation="css">
        <Drawer
          // container={container}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          // classes={{
          //   paper: classes.drawerPaper,
          // }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </Root>)
  );
}
