import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import TextField from '@mui/material/TextField'
import axios from 'axios'
import { Formik } from 'formik'
import * as yup from 'yup'
import { createCompany, updateUser } from '../../api/client'
import { SpinnerAdornment } from '../SpinnerAdornment'

const validationSchema = yup.object({
  name: yup.string().required('Please provide your name'),
  companyName: yup.string().required('Please provide a name for your company'),
  industry: yup.string().required('Please select your companies industry'),
})

export default function CreateCompanyModal({ open, handleClose }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown={true}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth={true}
    >
      <Box padding="40px">
        <span
          style={{
            fontSize: '62px',
            display: 'block',
            textAlign: 'center',
            marginTop: '30px',
            marginBottom: '20px',
          }}
        >
          👋
        </span>
        <Typography variant="h2" align="center">
          Welcome to Paradym Shift
        </Typography>
        <Typography paragraph>
          Before you begin your journey to becoming a peak performing sales
          organization we need to know a little bit about your company.
        </Typography>
        <Typography variant="subtitle2">
          Note: This information is used to tailor your dashboard and data to
          you.
        </Typography>
        <Formik
          initialValues={{
            name: '',
            companyName: '',
            industry: '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            axios
              .all([
                updateUser(values.name),
                createCompany(values.companyName, values.industry),
              ])
              .then(
                axios.spread(function (user) {
                  handleClose(user)
                  setSubmitting(false)
                })
              )
          }}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <DialogContent>
                <Box minHeight="80px">
                  <TextField
                    variant="outlined"
                    margin="normal"
                    name="name"
                    label="Your Name"
                    type="text"
                    fullWidth
                    error={!!props.errors.name && props.touched.name}
                    helperText={props.errors.name}
                    value={props.values.name}
                    onChange={props.handleChange}
                  />
                </Box>
                <Box minHeight="100px">
                  <TextField
                    variant="outlined"
                    margin="normal"
                    name="companyName"
                    label="Company Name"
                    type="text"
                    fullWidth
                    error={
                      !!props.errors.companyName && props.touched.companyName
                    }
                    helperText={props.errors.companyName}
                    value={props.values.companyName}
                    onChange={props.handleChange}
                  />
                </Box>
                <Box minHeight="80px">
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="industry-label">Industry</InputLabel>
                    <Select
                      id="industry"
                      name="industry"
                      labelId="industry-label"
                      value={props.values.industry}
                      error={!!props.errors.industry && props.touched.industry}
                      onChange={props.handleChange}
                      label="Industry"
                    >
                      <MenuItem></MenuItem>
                      <MenuItem value="Administrative and Support Services">
                        Administrative and Support Services
                      </MenuItem>
                      <MenuItem value="Airline, Aero space, Air Transport">
                        Airline, Aero space, Air Transport
                      </MenuItem>
                      <MenuItem value="Agriculture, Agribusiness, Farming">
                        Agriculture, Agribusiness, Farming
                      </MenuItem>
                      <MenuItem value="Arts and Recreation Services">
                        Arts and Recreation Services
                      </MenuItem>
                      <MenuItem value="Auto, Automotive">
                        Auto, Automotive
                      </MenuItem>
                      <MenuItem value="Banking, Insurance and Finance">
                        Banking, Insurance and Finance
                      </MenuItem>
                      <MenuItem value="Building and Construction">
                        Building and Construction
                      </MenuItem>
                      <MenuItem value="Communications">Communications</MenuItem>
                      <MenuItem value="Education and Training">
                        Education and Training
                      </MenuItem>
                      <MenuItem value="Electricity, Gas and Utilities">
                        Electricity, Gas and Utilities
                      </MenuItem>
                      <MenuItem value="Entertainment Industry">
                        Entertainment Industry
                      </MenuItem>
                      <MenuItem value="Food and Beverage Services">
                        Food and Beverage Services
                      </MenuItem>
                      <MenuItem value="Government,Public Administration and Safety">
                        Government,Public Administration and Safety
                      </MenuItem>
                      <MenuItem value="Health, Healthcare and Social services">
                        Health, Healthcare and Social services
                      </MenuItem>
                      <MenuItem value="Hotels and Tourism">
                        Hotels and Tourism
                      </MenuItem>
                      <MenuItem value="Internet">Internet</MenuItem>
                      <MenuItem value="Information Media and Telecomunications">
                        Information Media and Telecomunications
                      </MenuItem>
                      <MenuItem value="Legal">Legal</MenuItem>
                      <MenuItem value="Manufacturing">Manufacturing</MenuItem>
                      <MenuItem value="Mining">Mining</MenuItem>
                      <MenuItem value="Printing, Publishing and Advertising">
                        Printing, Publishing and Advertising
                      </MenuItem>
                      <MenuItem value="Professional, Scientific and Technical Services">
                        Professional, Scientific and Technical Services
                      </MenuItem>
                      <MenuItem value="Rental, Hiring and Real Estate Services">
                        Rental, Hiring and Real Estate Services
                      </MenuItem>
                      <MenuItem value="Retail Trade">Retail Trade</MenuItem>
                      <MenuItem value="Sport">Sport</MenuItem>
                      <MenuItem value="Technology">Technology</MenuItem>
                      <MenuItem value="Transport, Postal and Logistics">
                        Transport, Postal and Logistics
                      </MenuItem>
                      <MenuItem value="Wholesale Trade">
                        Wholesale Trade
                      </MenuItem>
                      <MenuItem value="Other Services">Other Services</MenuItem>
                    </Select>
                    <FormHelperText>{props.errors.industry}</FormHelperText>
                  </FormControl>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  size="large"
                  variant="outlined"
                  disabled={props.isSubmitting}
                  color="primary"
                  type="submit"
                >
                  Create
                  {props.isSubmitting && <SpinnerAdornment />}
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Box>
    </Dialog>
  )
}
