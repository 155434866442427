import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined'
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import HomeIcon from './HomeIcon'
import LightBulbIcon from './LightBulbIcon'

export const Repository = {
  dashboard: <HomeIcon />,
  opportunities: <LightBulbIcon />,
  learning_courses: <SchoolOutlinedIcon />,
  sales_effectiveness_diagnostic: <FindInPageOutlinedIcon />,
  customer_service_diagnostic: <FindInPageOutlinedIcon />,
  settings: <SettingsOutlinedIcon />,
}
