import CircularProgress from '@mui/material/CircularProgress'
import { styled } from '@mui/material/styles';
const PREFIX = 'SpinnerAdornment';

const classes = {
  spinner: `${PREFIX}-spinner`
};

const StyledCircularProgress = styled(CircularProgress)({
  [`& .${classes.spinner}`]: {
    marginLeft: 5,
  },
});

export const SpinnerAdornment = (() => (
  <StyledCircularProgress style={{ marginLeft: '10px' }} size={20} />
))
