import Bugsnag from '@bugsnag/js'
import { CacheProvider } from '@emotion/react'
import { Alert, Button } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import Tracker from '@openreplay/tracker/cjs'
import { Decimal } from 'decimal.js'
import { User } from 'next-auth'
import { SessionProvider } from 'next-auth/react'
import { appWithTranslation } from 'next-i18next'
import Head from 'next/head'
import Router, { useRouter } from 'next/router'
import NProgress from 'nprogress'
import React, { useEffect, useState } from 'react'
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query'
import 'reflect-metadata'
import superjson from 'superjson'
import { stopUndercover } from '../api/client'
import Layout from '../components/layout'
import CreateCompanyModal from '../components/settings/CreateCompanyModal'
import { createQueryClient } from '../graphql/api'
import theme from '../helpers/theme'
import * as gtag from '../lib/gtag'
import '../print.css'
import '../styles.scss'
import createEmotionCache from '../utils/createEmoticonCache'
import Error from './_error'

// Notice how we track pageview when route is changed
Router.events.on('routeChangeComplete', (url) => gtag.pageview(url))

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React)
const clientSideEmotionCache = createEmotionCache()
const tracker = new Tracker({
  projectKey: '3JG3osMdmDM1rzhr2arN',
})

superjson.registerCustom<Decimal, string>(
  {
    isApplicable: (v): v is Decimal => Decimal.isDecimal(v),
    serialize: (v) => v.toJSON(),
    deserialize: (v) => new Decimal(v),
  },
  'decimal.js'
)

const MyApp = (props) => {
  const [queryClient] = useState<QueryClient>(createQueryClient)
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props

  const session = pageProps.session
  const user: User | null = session?.user
  const router = useRouter()

  useEffect(() => {
    const handleStart = (url: string) => {
      console.log(`Loading: ${url}`)
      NProgress.start()
    }

    const handleStop = () => {
      NProgress.done()
    }

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleStop)
    router.events.on('routeChangeError', handleStop)

    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleStop)
      router.events.off('routeChangeError', handleStop)
    }
  }, [router])

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement!.removeChild(jssStyles)
    }

    tracker.start({
      userID: session?.agent_email || user?.email,
      metadata: {
        role: user?.role || "",
        company_id: user?.company?.id?.toString() || "",
      },
      forceNew: false,
    })
  }, [session])

  const [showCreateCompanyModal, setShowCreateCompanyModal] = React.useState(
    session ? session.user.company == null : false
  )

  const stopUndercoverSession = async () => {
    await stopUndercover()
    await router.push('/admin')
    router.reload()
  }

  const handleCloseCreateCompanyModal = (user, reason) => {
    if (reason === 'backdropClick') {
      return false
    }
    setShowCreateCompanyModal(false)
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary FallbackComponent={Error}>
        <React.Fragment>
          <CacheProvider value={emotionCache}>
            <Head>
              <title>Paradym Shift</title>
              <meta
                name="viewport"
                content="minimum-scale=1, initial-scale=1, width=device-width"
              />
            </Head>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <SessionProvider
                session={session as any}
                refetchInterval={5 * 60}
              >
                <div style={{ display: 'flex', width: '100%' }}>
                  <div
                    style={{
                      position: 'absolute',
                      paddingLeft: '260px',
                      width: '100%',
                    }}
                  >
                    {session?.undercover && (
                      <Alert
                        id="undercover-alert"
                        severity="warning"
                        action={
                          <Button
                            variant="outlined"
                            onClick={() => stopUndercoverSession()}
                          >
                            Finished
                          </Button>
                        }
                        style={{
                          backgroundColor: '#fff4e5d1',
                          left: '0px',
                          width: '100%',
                          position: 'absolute',
                          zIndex: 10000,
                        }}
                      >
                        Currently undercover as user:{' '}
                        {session.user.name} (
                        {session.user.email})
                      </Alert>
                    )}
                  </div>
                  <Layout>
                    <Hydrate state={pageProps.dehydratedState}>
                      <CssBaseline />
                      <Component {...pageProps} />
                    </Hydrate>
                  </Layout>
                  <CreateCompanyModal
                    open={showCreateCompanyModal}
                    handleClose={handleCloseCreateCompanyModal}
                  />
                </div>
              </SessionProvider>
            </ThemeProvider>
          </CacheProvider>
        </React.Fragment>
      </ErrorBoundary>
    </QueryClientProvider>
  )
}

export default appWithTranslation(MyApp)
