

import { GraphQLClient } from "graphql-request";
import { QueryClient } from "react-query";
import { localFileSource } from "../helpers/FileHelper";

import { getSdk } from "./generated/graphql";

export const createQueryClient = () => new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});


export const getGqlSdk = (request?) => {
  const gqlClient = new GraphQLClient(localFileSource("/api/graphql"))
  if (request) {
    gqlClient.setHeaders({ ...request.headers })
  }
  return getSdk(gqlClient);
}