import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, IconButton, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useRouter } from 'next/router';
import React from 'react';
import Sidebar from './Sidebar';

const PREFIX = 'layout';

const classes = {
  root: `${PREFIX}-root`,
  logo: `${PREFIX}-logo`,
  hide: `${PREFIX}-hide`,
  content: `${PREFIX}-content`,
  large: `${PREFIX}-large`,
  icon: `${PREFIX}-icon`,
  divider: `${PREFIX}-divider`,
  appBar: `${PREFIX}-appBar`,
  menuButton: `${PREFIX}-menuButton`,
  drawer: `${PREFIX}-drawer`,
  toolbar: `${PREFIX}-toolbar`,
  fullPage: `${PREFIX}-fullPage`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
  },

  [`& .${classes.logo}`]: {
    width: 40,
    height: 40,
    marginRight: 10,
  },

  [`& .${classes.hide}`]: {
    display: 'none',
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
    '@media screen': {
      // backgroundColor: theme.palette.background.default,
      paddingTop: theme.spacing(8),
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(14),
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(0),
        marginLeft: "280px",
      },
    },
    '@media print': {
      padding: 0,
    },
  },

  [`& .${classes.large}`]: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },

  [`& .${classes.icon}`]: {
    color: '#e4e4e4',
  },

  [`& .${classes.divider}`]: {
    backgroundColor: '#ffffff1f',
  },

  [`& .${classes.appBar}`]: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
      // width: `calc(100% - ${drawerWidth}px)`,
      // marginLeft: drawerWidth,
    },
    backgroundColor: '#fff',
    '@media print': {
      display: 'none',
    },
  },

  [`& .${classes.menuButton}`]: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },

  [`& .${classes.drawer}`]: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'inherit',
      width: "280px",
      flexShrink: 0,
    },
    '@media print': {
      display: 'none',
    },
  },

  [`& .${classes.toolbar}`]: theme.mixins.toolbar,

  [`& .${classes.fullPage}`]: {
    position: 'fixed',
    top: '50%',
    paddingLeft: '260px',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    textAlign: 'center',
  }
}));

export default function Layout({ children }) {

  const [mobileOpen, setMobileOpen] = React.useState(false)
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const router = useRouter()
  const displayNavigation =
    !router.pathname.startsWith('/auth') &&
    !router.pathname.startsWith('/admin') &&
    !router.pathname.includes('sales_effectiveness_diagnostic/survey/') &&
    !router.pathname.includes('customer_service_diagnostic/survey/') &&
    !router.pathname.startsWith('/404') &&
    !router.pathname.startsWith('/403')
  return (
    (<Root sx={{ width: "100%" }
    }>
      {displayNavigation && (
        <>
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="primary"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <nav className={classes.drawer}>
            <Sidebar
              mobileOpen={mobileOpen}
              handleDrawerToggle={handleDrawerToggle}
            />
          </nav>
        </>
      )}
      <main className={classes.content} style={{ marginLeft: displayNavigation ? "280px" : "0px" }}>{children}</main>
    </Root >)
  );
}
