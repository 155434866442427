import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { getSession } from 'next-auth/react'
import getConfig from 'next/config'
import React from 'react'
const { serverRuntimeConfig, publicRuntimeConfig } = getConfig()

const bugsnag = Bugsnag._client ?? Bugsnag.start({
  apiKey: serverRuntimeConfig.BUGSNAG_API_KEY || publicRuntimeConfig.BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact(React)],
  appVersion: process.env.APP_VERSION,
  releaseStage: publicRuntimeConfig.NODE_ENV,
  enabledReleaseStages: [ 'production', 'staging' ],
  onError: async function (event) {
    const session = await getSession()
    if(!session) return true;

    event.addMetadata('user', {
      name: session.user.name,
      email: session.user.email,
      role: session.user.role,
      admin: session.user.admin,
      company_id: session.user.company.id,
      undercover: session.user.undercover_email
    })
    return true;
  }
})

export default bugsnag