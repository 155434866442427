export const GA_TRACKING_ID = 'UA-100567924-4' // This is your GA Tracking ID
const isProduction = process.env.NODE_ENV === "production";

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  if (!isProduction) return;
  if (window.gtag !== 'undefined') {
  window.gtag('config', GA_TRACKING_ID, {
    page_path: url,
  })
}
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
  if (!isProduction) return;
  if (window.gtag !== 'undefined') {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  })
}
}