import axios from 'axios'
import { ReportData } from '../interfaces/opportunity_qualifier_interface'

export function stopUndercover() {
  return fetch('/api/admin/undercover', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(null),
  }).then((response) => response.json())
}

export function startUndercover(user) {
  return fetch('/api/admin/undercover', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  }).then((response) => response.json())
}

export function createSalesEffectivenessDiagnostic(closingDate) {
  return fetch('/api/sales_effectiveness_diagnostics', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ closingDate }),
  }).then((response) => response.json())
}

export function createCustomerServiceDiagnostic(closingDate) {
  return fetch('/api/customer_service_diagnostics', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ closingDate }),
  }).then((response) => response.json())
}

export function createCustomerServiceDiagnosticResponse(
  id,
  answers
) {
  return axios.post(
    `/api/customer_service_diagnostics/${id}`,
    { answers }
  )
}

export function updateCustomerServiceDiagnostic(closingDate) {
  return fetch(`/api/customer_service_diagnostics`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ closingDate }),
  }).then((response) => response.json())
}


export function getSalesEffectivenessDiagnostic(id) {
  return fetch(`/api/sales_effectiveness_diagnostics/${id}`, {
    method: 'GET',
  }).then((response) => response.json())
}

export function getSalesEffectivenessDiagnostics() {
  return fetch('/api/sales_effectiveness_diagnostics', {
    method: 'GET',
  }).then((response) => response.json())
}

export function createSalesEffectivenessDiagnosticResponse(
  sales_effectiveness_diagnostic_id,
  answers
) {
  return axios.post(
    `/api/sales_effectiveness_diagnostics/${sales_effectiveness_diagnostic_id}`,
    { answers }
  )
}

export function createCompany(name, industry) {
  return axios.post('/api/companies', { name, industry })
}

export function updateUser(name) {
  return axios.put('/api/user', { name })
}

export async function getCustomers() {
  return fetch(`/api/customers`, {
    method: 'GET',
  }).then((response) => response.json())
}

export function createQualifier(opportunity_id, answers) {
  return fetch(`/api/opportunities/${opportunity_id}/qualifier`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      answers,
    }),
  }).then((response) => response.json())
}

export function updateActionItem(opportunityId, actionItemId, outcome) {
  return fetch(
    `/api/opportunities/${opportunityId}/action_items/${actionItemId}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        outcome,
      }),
    }
  ).then((response) => response.json())
}

export function createCompetitorAnalysis(
  opportunity_id,
  incumbentName,
  answers
) {
  return fetch(`/api/opportunities/${opportunity_id}/competitor_analysis`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      incumbentName,
      answers,
    }),
  }).then((response) => response.json())
}

export function createOpportunityQualifier(createOpportunityQualifierProps) {
  return fetch('/api/opportunity_qualifiers', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(createOpportunityQualifierProps),
  }).then((response) => response.json())
}

export function generateActionPlan(answers) {
  return fetch('/api/opportunity_qualifiers/action_plan', {
    method: 'POST',
    body: JSON.stringify(answers),
    headers: {
      contentType: 'application/json',
    },
  }).then((response) => response.json())
}

export function generateReport(data: ReportData) {
  return fetch('/api/opportunity_qualifiers/report', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/pdf',
    },
    body: JSON.stringify(data),
  }).then((response) => response.blob())
}

export function upsertRelationshipMap(opportunityId, relationshipMap) {
  return axios.put(
    `/api/opportunities/${opportunityId}/relationship_map`,
    relationshipMap
  )
}
