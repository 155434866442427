/* eslint-disable */

import { red } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'
// it could be your App.tsx file or theme file that is included in your tsconfig.json
import { Theme } from '@mui/material/styles'

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme { }
}

// Create a theme instance.
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1500,
    },
  },
  palette: {
    primary: {
      main: '#0c2c63',
    },
    secondary: {
      main: '#EFAF3A',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#f5f5f5',
    },
  },
  typography: {
    h1: {
      fontFamily: 'Nunito',
      marginBottom: '10px',
      fontSize: '2.5em',
      lineHeight: '1em',
      color: '#041531',
    },
    h2: {
      fontFamily: 'Nunito',
      lineHeight: '2em',
      fontSize: '2em',
      color: '#041531',
      marginBottom: '15px',
    },
    h3: {
      fontFamily: 'Nunito',
      fontSize: '1.6em',
      marginBottom: '10px',
    },
    h4: {
      fontFamily: 'Nunito',
      fontSize: '1.4em',
      marginBottom: '10px',
    },
    h5: {
      fontFamily: 'Nunito',
      fontSize: '1.3em',
      marginBottom: '10px',
      fontWeight: 'bold',
    },
    subtitle1: {
      fontSize: '1.6em',
      color: '#a0a0a0',
      marginBottom: '5px',
    },
    subtitle2: {
      fontSize: '1.0em',
      color: '#888',
      fontStyle: 'italic',
      marginBottom: '5px',
    },
    body1: {
      fontSize: '1em',
      lineHeight: '2em',
      fontFamily: 'Open Sans',
    },
    button: {
      textTransform: 'none',
    },
  },
})

export default theme
